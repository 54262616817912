import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { selectGameButtonHarness, button, checkoutButton } from '../styles/button.module.scss'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import EmailBox from "./email-box"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import axios from "axios";

const CheckoutFree = ({ baseUrl, sku, buttonText, setVideoPlaying }) => {
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);

  const toggleModal = () => {
    const showModalNow = !showModal;
    setVideoPlaying(!showModalNow);
    setShowModal(!showModal);
  }

  const beginCheckoutProcess = (event) => {
    event.preventDefault();
    setTransactionId(`${uuidv4()}`);
    toggleModal(); // shows email box
  }

  const handleEmailSubmit = (event, emailAddress, optin) => {
    event.preventDefault();

    addToMailchimp(emailAddress, { TID: transactionId })
      .then(data => {
        console.log('MAILCHIMP RESPONSE:', data);

        axios.post(`${process.env.GATSBY_API_ENDPOINT}/api/piing-party/free`, {
          email: emailAddress,
          client_reference_id: transactionId,
          sku_id: sku
        })
          .then(res => window.location = `${baseUrl}/success?id=${transactionId}`)
          .catch(err => console.warn("piing party create error:", err));
      })
  }

  return (
    <div className={selectGameButtonHarness}>
      <button id={'select-game-button'} data-sku={sku} className={classNames(button, checkoutButton)} onClick={event => beginCheckoutProcess(event)}>
        { buttonText }
      </button>
      <EmailBox isOpen={showModal} handleClose={toggleModal} handleSubmit={handleEmailSubmit} requireOptin />
    </div>
  )
}

export default CheckoutFree