import React, { useRef, useState, useEffect, useMemo } from "react"
import { createPortal } from "react-dom";
import classNames from 'classnames'
import { buttonHarness, button, checkoutButton } from '../styles/button.module.scss'
import modalStyles from '../styles/modal.module.scss'
import emailBoxStyles from '../styles/email-box.module.scss'
import { PiingPartyLogo } from "./piing-logo";
import Spinner from "./spinner";
import isEmail from 'validator/lib/isEmail';

export const Checkbox = ({ children, id, isSelected, toggleSelected }) => {
  return (
    <div className={emailBoxStyles.checkbox}>
      <input
        type="checkbox"
        id={id}
        name={id}
        checked={isSelected}
        onChange={toggleSelected}
        className={emailBoxStyles.checkboxInput}
      />
      <label className={emailBoxStyles.checkboxLabel} htmlFor={id}>
        <div className={emailBoxStyles.checkboxBox} />
        {children}
      </label>
    </div>
  )
}

const EmailBox = ({ isOpen, handleClose, handleSubmit, requireOptin = false }) => {
  const emailInputRef = useRef(null);
  const [emailAddress, setEmailAddress] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [optIn, setOptIn] = useState(false);
  const [termsAgree, setTermsAgree] = useState(false);

  const emailIsValid = useMemo(() => {
    const validEmail = isEmail(emailAddress);
    // console.log('valid email? email:', emailAddress, 'valid:', validEmail);
    return validEmail;
  }, [emailAddress])

  const checkoutButtonEnabled = useMemo(() => {
    const termsAndValidEmail = termsAgree && emailIsValid;
    return requireOptin ? termsAndValidEmail && optIn : termsAndValidEmail;
  }, [termsAgree, emailIsValid, optIn, requireOptin]);

  const handleBuyButton = (event) => {
    event.preventDefault();
    setShowSpinner(true);
    handleSubmit(event, emailInputRef.current.value, optIn);
  }

  const toggleOptin = () => {
    setOptIn(!optIn);
  }

  const toggleTermsAgree = () => {
    setTermsAgree(!termsAgree);
  }

  const updateEmailAddress = () => {
    setEmailAddress(emailInputRef.current.value);
  }

  useEffect(() => {
    if (isOpen) emailInputRef.current.focus();
  }, [isOpen]);

  return (
    <>
      {
        isOpen &&
        createPortal(
          <div className={modalStyles.modalContainer}>
            <div className={modalStyles.modalClickscreen} onClick={() => showSpinner ? null : handleClose()} />
            {
              showSpinner
                ? <Spinner colour='white' />
                :
                <div className={modalStyles.modalBox}>
                  <button className={modalStyles.closeButton} onClick={handleClose}>&times;</button>
                  <PiingPartyLogo small />
                  <div className={emailBoxStyles.formHarness}>
                    <input className={emailBoxStyles.input} ref={emailInputRef} placeholder={'enter your email'} onChange={updateEmailAddress} />
                    <Checkbox id={'terms-checkbox'} isSelected={termsAgree} toggleSelected={toggleTermsAgree}>
                      I agree to the <a href='/piing-party-terms.pdf' target={'_blank'} rel={'noopener noreferrer'}>terms &amp; conditions</a>
                    </Checkbox>
                    <Checkbox id={'opt-in-checkbox'} isSelected={optIn} toggleSelected={toggleOptin}>
                      Send me cool Piing stuff
                    </Checkbox>
                  </div>
                  <div className={buttonHarness}>
                    <button className={classNames(button, checkoutButton)} disabled={!checkoutButtonEnabled} onClick={event => handleBuyButton(event)}>Give me the game</button>
                  </div>
                  <a className={emailBoxStyles.privacyLink} href='https://piing.events/privacy' target={'_blank'} rel={'noopener noreferrer'}>Privacy policy</a>
                </div>
            }
          </div>
          , document.body)
      }
    </>
  )
}

export default EmailBox