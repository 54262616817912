import React, { useEffect, useState, useMemo, useRef } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactPlayer from 'react-player';
import textStyles from "../styles/text.module.scss"
import { PiingPartyLogo } from "../components/piing-logo"
import GameDisplay from "../components/game"
import PiingParty1PosterImage from "../images/piingparty1-poster.jpg"
import BuggyRaceNightPosterImage from "../images/buggy-race-night-poster.jpg"
import BuggyRaceDayPosterImage from "../images/buggy-race-day-poster.jpg"
import CookieConsent from "react-cookie-consent"
import cookieStyles from "../styles/cookie-consent.module.scss"
import buttonStyles from "../styles/button.module.scss"

const IndexPage = ({ location }) => {
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(true);

  useEffect(() => {
    setVideoUrl('https://stream.mux.com/zL89e1Xe3j6X9iXa8Ye1PnOtU8qJpC302.m3u8');
  }, []);

  const baseUrl = useMemo(() => {
    return location.origin;
  }, [location]);

  return (
    <Layout showHeader={false}>
      <SEO title="Piing Party" />
      <ReactPlayer
        url={videoUrl}
        className='react-player'
        ref={videoRef}
        width='100%'
        height='auto'
        playing={videoPlaying}
        loop
        playsinline
        muted
        config={{
          file: { 
            attributes: { 
              poster: PiingParty1PosterImage 
            } 
          } 
        }}
      />

      <PiingPartyLogo />

      <div className={classNames(textStyles.title, textStyles.big)}>
        Spice up your <span className={textStyles.highlight}>video calls*</span> with fun, fast-paced games for up to <span className={textStyles.highlight}>30</span> people!
      </div>

      <div className={textStyles.body}>
        *Zoom or any video call service that lets you share your screen.<br />Find out more in our <Link to={'/faq'}>FAQs</Link> and <Link to={'/quick-start'}>Quick Start Guide</Link>
      </div>

      <a name="games"/>

      <div className='games'>
        <GameDisplay 
          baseUrl={baseUrl}
          gameId={"buggy_race_day"} 
          imageOnLeft 
          videoPlaying={videoPlaying}
          setVideoPlaying={setVideoPlaying} 
          streamUrl={'https://stream.mux.com/lg8cgJb9dFssaofzcT00x7X9ec4TPcdOh.m3u8'}
          posterImage={BuggyRaceDayPosterImage}
        />
        <GameDisplay 
          baseUrl={baseUrl}
          gameId={"buggy_race_night"} 
          imageOnLeft={false} 
          videoPlaying={videoPlaying}
          setVideoPlaying={setVideoPlaying} 
          streamUrl={'https://stream.mux.com/8wjrm4wNdU7c2H1JQp3BWTJTIWq6vi00I.m3u8'}
          posterImage={BuggyRaceNightPosterImage}
        />
      </div>

      <div className={textStyles.body}>
        Find out more in our <Link to={'/faq'}>FAQs</Link> and <Link to={'/quick-start'}>Quick Start Guide</Link>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="OK"
        declineButtonText="Decline"
        disableStyles={true}
        buttonClasses={ classNames(buttonStyles.button, buttonStyles.cookieButton) }
        containerClasses={ cookieStyles.banner }
        contentClasses={ cookieStyles.content }
        cookieName="gatsby-gdpr-google-analytics"
      >
        This site uses cookies. <a href='https://piing.events/privacy'>Learn more</a>
      </CookieConsent>
    </Layout>
  )
}

export default IndexPage
