import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { buttonHarness, button, checkoutButton } from '../styles/button.module.scss'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import PiingLogo from "./piing-logo"
import EmailBox from "./email-box"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_API_KEY}`)

const redirectToCheckout = async (baseUrl, sku, transactionId, emailAddress) => {
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    items: [{ sku: sku, quantity: 1 }],
    clientReferenceId: transactionId,
    customerEmail: emailAddress,
    successUrl: `${baseUrl}/success?id=${transactionId}`,
    cancelUrl: `${baseUrl}/`,
  })

  if (error) {
    console.warn("redirectToCheckout error:", error)
  }
}

const Checkout = ({ baseUrl, sku, buttonText, setVideoPlaying }) => {
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);

  const toggleModal = () => {
    const showModalNow = !showModal;
    setVideoPlaying(!showModalNow);
    setShowModal(!showModal);
  }

  const beginCheckoutProcess = (event) => {
    event.preventDefault();
    setTransactionId(`${uuidv4()}`);
    toggleModal();
  }

  const handleEmailSubmit = (event, emailAddress, optin) => {
    event.preventDefault();

    if (optin !== true) redirectToCheckout(baseUrl, sku, transactionId, emailAddress);
    else {
      addToMailchimp(emailAddress, {
        TID: transactionId
      })
        .then(data => {
          // console.log('MAILCHIMP RESPONSE:', data);
          redirectToCheckout(baseUrl, sku, transactionId, emailAddress);
        })
    }
  }

  return (
    <div className={buttonHarness}>
      <button id={'select-game-button'} data-sku={sku} className={classNames(button, checkoutButton)} onClick={event => beginCheckoutProcess(event)}>
        {buttonText}
      </button>
      <EmailBox isOpen={showModal} handleClose={toggleModal} handleSubmit={handleEmailSubmit} />
    </div>
  )
}

export default Checkout