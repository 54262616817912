import React, { useMemo, useEffect, useState } from "react"
import "../styles/game.scss"
import ReactPlayer from 'react-player';
import Checkout from "./checkout";
import CheckoutFree from "./checkout-free";
import { getGameDataById } from "../utils/content";

const GameDisplay = ({baseUrl, gameId, imageOnLeft=false, setVideoPlaying, videoPlaying, streamUrl, posterImage}) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const gameData = useMemo(() => {
    const data = getGameDataById(gameId);
    return data;
  }, [gameId]);

  useEffect(() => {
    setVideoUrl(streamUrl);
  }, []);

  return (
    <div className={`game ${imageOnLeft ? 'game--image-on-left' : 'game--image-on-right'}`}>
      <div className='game__panel'>
        <ReactPlayer
          url={videoUrl}
          className={'game__video'}
          width={'auto'}
          height={'auto'}
          playing={videoPlaying}
          loop
          playsinline
          muted
          config={{
            file: { 
              attributes: { 
                poster: posterImage 
              } 
            } 
          }}
        />
      </div>

      <div className='game__panel'>
        <div className='game__title'>{ gameData.title }</div>
        <div className='game__subtitle'>{ gameData.subtitle }</div>

        {
          process.env.GATSBY_FREE_OFFER === 'false' &&
          <>
            <div className='game__players'>{ gameData.players }</div>
            <div className='game__description'>{ gameData.description }</div>
            <div className='game__price'>
              Unlimited plays for { gameData.length } / £{ gameData.price }
            </div>
          </>
        }

        {
          process.env.GATSBY_FREE_OFFER === 'true' &&
          <div className='game__free'>           
            <div className='game__price'>{ gameData.players }<br />{ gameData.length }, { gameData.plays } plays</div>
            <div className='game__players'>
              FREE TO PLAY
            </div> 
            <div className='game__subtext'>Save £{ gameData.price } for a limited time</div>
          </div>
        }

        {/*  CHECKOUT BUTTON  */}

        {
          process.env.GATSBY_FREE_OFFER === 'false' &&
          <Checkout 
            baseUrl={baseUrl}
            sku={ gameData.sku }
            buttonText={ "Play now" }
            setVideoPlaying={setVideoPlaying} 
          />
        }
        {
          process.env.GATSBY_FREE_OFFER === 'true' &&
          <CheckoutFree 
            baseUrl={baseUrl}
            sku={ gameData.sku }
            buttonText={ "Play now for FREE" }
            setVideoPlaying={setVideoPlaying} 
          />
        }
        
      </div>
    </div>
  )  
}

export default GameDisplay
